<template>
  <div class="project-configuration-environment">
    <div>
      <section class="two-column-section">
        <header class="two-column-section__header">
          <h2 class="h5">
            {{ $t('general') }}
          </h2>
        </header>

        <div class="two-column-section__content">
          <label>{{ $t('icon') }}</label>
          <div class="project-configuration-environment__icon">
            <CyInputsIconPicker
              :key="envCanonical"
              :color-name.sync="environment.color"
              :icon.sync="environment.icon"
              @update:colorName="debouncedUpdateEnv"
              @update:icon="debouncedUpdateEnv"/>
            <v-progress-circular
              v-if="saving"
              class="ml-4"
              indeterminate
              :width="1"
              :size="20"
              color="grey"/>
            <v-icon
              v-if="iconUpdatedSuccessfully && !saving"
              class="ml-3"
              color="green">
              check
            </v-icon>
          </div>
        </div>
      </section>

      <v-divider class="my-8"/>

      <section class="two-column-section">
        <header class="two-column-section__header">
          <h2 class="h5">
            {{ $t('configuration') }}
          </h2>
        </header>

        <div class="two-column-section__content">
          <v-card
            outlined
            @click="$router.push({ name: 'environmentConfig', params: { envCanonical } })">
            <v-card-text class="card-wrapper">
              <CyAvatar
                class="card-wrapper__avatar"
                :item="{
                  icon: 'tune',
                  color: 'dev',
                }"/>
              <div class="card-wrapper__text">
                <div>
                  <h3 class="content__title">
                    {{ $t('config.title') }}
                  </h3>
                  <div
                    class="content__subtitle"
                    v-html="$sanitizeHtml($t('config.description'))"/>
                </div>
                <CyButton
                  class="content__button"
                  icon="chevron_right"
                  icon-only
                  variant="tertiary"
                  theme="primary"
                  sm/>
              </div>
            </v-card-text>
          </v-card>
        </div>
      </section>

      <v-divider class="my-8"/>

      <section class="two-column-section">
        <header class="two-column-section__header">
          <h2 class="h5">
            {{ $t('scheduling.sectionTitle') }}
          </h2>
        </header>

        <div class="two-column-section__content">
          <v-card
            outlined
            @click="$toggle.showScheduleModal">
            <v-card-text class="card-wrapper">
              <CyAvatar
                class="card-wrapper__avatar"
                :item="{
                  icon: 'event',
                  color: 'dev',
                }"/>

              <div class="card-wrapper__text">
                <div>
                  <h3 class="content__title">
                    {{ $t('scheduling.title') }}
                  </h3>
                  <div
                    class="content__subtitle"
                    v-html="$sanitizeHtml($t('scheduling.description'))"/>
                </div>
                <CyButton
                  class="content__button"
                  icon="chevron_right"
                  icon-only
                  variant="tertiary"
                  theme="primary"
                  sm/>
              </div>
            </v-card-text>
            <v-divider v-if="!_.isEmpty(getStartStopPipeline)"/>
            <v-card-text
              v-if="!_.isEmpty(getStartStopPipeline)"
              v-ripple.prevent.stop="false"
              :ripple="false"
              class="content"
              @click.prevent.stop="">
              <div class="content__button">
                <CyButton
                  v-has-rights-to="['CreateBuild', projectCanonical]"
                  aria-label="Start"
                  icon="play_arrow"
                  variant="secondary"
                  theme="primary"
                  sm
                  @click.prevent.stop="startStopEnvironment('start')">
                  {{ $t('pipeline.start') }}
                </CyButton>
                <CyButton
                  v-has-rights-to="['CreateBuild', projectCanonical]"
                  aria-label="Stop"
                  icon="stop"
                  variant="secondary"
                  theme="primary"
                  sm
                  @click.prevent.stop="startStopEnvironment('stop')">
                  {{ $t('pipeline.stop') }}
                </CyButton>
              </div>
              <span class="content__link">
                <router-link
                  role="link"
                  class="cy-link"
                  :to="{
                    name: 'pipeline',
                    params: { pipelineCanonical: getStartStopPipeline.name, backRouteTo: $route.name },
                  }"
                  @click.native.stop="">
                  {{ $t('pipeline.viewStartStop') }}
                </router-link>
              </span>
            </v-card-text>
          </v-card>
        </div>
      </section>

      <v-divider class="my-8"/>

      <section class="two-column-section">
        <header class="two-column-section__header">
          <h2 class="h5">
            {{ $t('externalBackends.iacStateManagement') }}
          </h2>
          <p class="subtitle">
            <i18n
              path="externalBackends.iacDescription"
              tag="p">
              <template #learnMore>
                <a
                  :href="$docLinks.terraform.state"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="cy-link">{{ $t('learnMore') }}</a>
              </template>
            </i18n>
          </p>
        </header>

        <div class="two-column-section__content">
          <v-card
            outlined
            :to="terraformConfigRoute">
            <v-card-text class="card-wrapper">
              <CyIconCredential
                class="card-wrapper__avatar"
                size="32"
                type="terraform"/>
              <div class="card-wrapper__text">
                <div>
                  <h3 class="content__title">
                    {{ $t('externalBackends.RemoteTfBackend') }}
                  </h3>
                  <div
                    class="content__subtitle"
                    v-html="$sanitizeHtml($t('externalBackends.remoteTfBackendHint'))"/>
                </div>
                <div class="content__button">
                  <div
                    v-if="loading.externalBackends"
                    class="sk-block sk-dark sk-h-5 sk-w-17 mr-3"/>
                  <CyTag
                    v-else
                    class="mr-3"
                    element-type="div"
                    small
                    :variant="externalBackendId ? 'secondary' : 'default'">
                    {{ $t(externalBackendId ? 'enabled' : 'disabled') }}
                  </CyTag>
                  <CyButton
                    icon="chevron_right"
                    icon-only
                    variant="tertiary"
                    theme="primary"
                    sm/>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </div>
      </section>

      <v-divider class="my-8"/>

      <section>
        <CyDangerZone
          v-has-rights-to="'UpdateProject'"
          class="two-column-section"
          :entity-name="envCanonical"
          :entity-type="_.toLower($t('Environment'))"
          :delete-button-text="`${$t('delete.title')}...`"
          :delete-title="$t('delete.title')"
          :delete-text="$t('delete.description')"
          @delete="deleteEnv">
          <template #confirmText>
            <p class="font-weight-bold mb-0">
              {{ $t('delete.modalDescriptionHeader') }}
            </p>
            <p class="font-weight-bold">
              {{ $t('delete.modalDescription') }}
            </p>
            <div class="d-flex align-center">
              <p class="ma-0 mr-2">
                {{ $t('delete.modalConfirm') }}
              </p>
              <span class="d-flex align-center font-weight-bold">
                <CyAvatar
                  :item="environment"
                  class="mr-1"
                  sm/>
                {{ envCanonical }}
              </span>
            </div>
          </template>
        </CyDangerZone>
      </section>
    </div>

    <CyFormsStartStop
      v-if="showScheduleModal"
      :edit="!_.isEmpty(getStartStopPipeline)"
      :environment="environment"
      :project-canonical="projectCanonical"
      @updated="GET_PROJECT_PIPELINES"
      @close="$toggle.showScheduleModal(false)"/>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { constructBreadcrumb, extractStartStopPipelines } from '@/utils/helpers'
import CyInputsIconPicker from '@/components/inputs/icon-picker'
import CyFormsStartStop from '@/components/forms/start-stop'
import CyDangerZone from '@/components/danger-zone'

export default {
  name: 'CyPageProjectConfigurationEnvironment',
  components: {
    CyInputsIconPicker,
    CyFormsStartStop,
    CyDangerZone,
  },
  props: {
    projectCanonical: {
      type: String,
      default: '',
    },
    envCanonical: {
      type: String,
      default: '',
    },
  },
  breadcrumb () {
    const { projectCanonical, projectName } = this
    return constructBreadcrumb(this.$options.name, this.$t('routes.projectConfiguration'), [
      {
        label: projectName,
        name: 'project',
        params: { projectCanonical },
      },
      {
        label: this.$t('routes.projectsSection'),
        name: 'projectsSection',
      },
    ])
  },
  header () {
    return { title: this.$t('routes.projectConfiguration') }
  },
  data: () => ({
    saving: false,
    loading: {
      start: false,
      stop: false,
      externalBackends: false,
    },
    environment: {},
    externalBackendId: null,
    iconUpdatedSuccessfully: false,
    showScheduleModal: false,
  }),
  computed: {
    ...mapState('organization/project', {
      errors: (state) => state.errors.env,
      pipelines: (state) => state.pipelines,
    }),
    ...mapGetters('organization/project', [
      'project',
      'infraViewExternalBackends',
      'envs',
    ]),
    terraformConfigRoute () {
      const { externalBackendId, envCanonical } = this
      const params = _.pickBy({ envCanonical, externalBackendId, backRouteTo: this.$route.name })
      return externalBackendId
        ? { name: 'projectConfigInfraView', params }
        : { name: 'newProjectConfigInfraView', params }
    },
    getStartStopPipeline () {
      let pipeline = {}
      if (!_.isEmpty(this.pipelines)) {
        pipeline = extractStartStopPipelines(this.pipelines)?.startStop
          .find(({ environment }) => environment.canonical === this.envCanonical)
      }
      return pipeline
    },
  },
  watch: {
    envCanonical () {
      this.setup()
    },
  },
  created () {
    this.setup()
  },
  methods: {
    ...mapActions('organization/project', [
      'GET_EXTERNAL_BACKENDS',
      'UPDATE_PROJECT',
      'GET_PROJECT_PIPELINES',
      'DELETE_ENV',
    ]),
    ...mapActions('alerts', [
      'SHOW_ALERT',
    ]),
    async setup () {
      const { envs, envCanonical } = this
      this.environment = _.find(envs, { canonical: envCanonical }) ?? {}
      this.$toggle.loading.externalBackends(true)
      await Promise.all([
        this.GET_PROJECT_PIPELINES(),
        this.GET_EXTERNAL_BACKENDS(),
      ])
      this.$toggle.loading.externalBackends(false)
      this.externalBackendId = _.get(_.find(this.infraViewExternalBackends, { environment_canonical: envCanonical }), 'id')
      this.$setOriginalData()
    },
    debouncedUpdateEnv: _.debounce(async function () {
      await this.updateEnv()
    }, 500),
    async updateEnv () {
      const { $data: { $originalData }, environment, updateProject } = this
      if (_.isEqual($originalData.environment, environment)) return
      await updateProject()
      this.$setOriginalData()

      this.$toggle.iconUpdatedSuccessfully(true)
      setTimeout(() => {
        this.$toggle.iconUpdatedSuccessfully(false)
      }, 2000)
    },
    async updateProject () {
      this.$toggle.saving(true)
      const { envs, environment, project } = this
      const updatedProject = {
        ...project,
        environments: _.uniqWith([...envs, environment], _.isEqual),
      }
      await this.UPDATE_PROJECT({ project: updatedProject, refetchProject: false, displaySuccessMessage: false })
      this.$toggle.saving(false)
    },
    async startStopEnvironment (type) {
      const { projectCanonical, envCanonical, orgCanonical } = this
      const pipelineCanonical = `start-stop-${projectCanonical}-${envCanonical}`
      this.loading[type] = true
      const { data } = await this.$cycloid.ydAPI.createBuild(orgCanonical, projectCanonical, pipelineCanonical, type) || {}
      if (data) {
        const successMessage = type === 'start' ? 'started' : 'stopped'
        this.SHOW_ALERT({ type: 'success', content: this.$t(`alerts.success.environment.${successMessage}`) })
      }
      this.loading[type] = false
    },
    async deleteEnv () {
      await this.DELETE_ENV({ envCanonical: this.envCanonical })
      if (_.isEmpty(this.errors)) this.$router.push({ name: 'projectConfiguration' })
    },
  },
  i18n: {
    messages: {
      en: {
        title: '@:routes.projectConfiguration',
        config: {
          title: 'Environment configuration',
          description: 'The environment configuration is composed of key/value pairs. Defined in the stack <code>.forms.yml</code> file, variables are filled when creating an environment and can be edited at any time.',
        },
        scheduling: {
          sectionTitle: 'Scheduling',
          title: 'Schedule',
          description: 'Set up automatic start and stop for this environment.',
        },
        delete: {
          title: 'Delete environment',
          description: 'Deleting this environment is a permanent action that cannot be undone. Resources created through pipelines will not be deleted automatically.',
          modalDescriptionHeader: 'Deleting an environment will not delete resources that have been deployed by the pipeline.',
          modalDescription: 'Please remove them via the pipeline job or manually as they may generate costs.',
          modalConfirm: 'Do you still want to delete this environment?',
        },

      },
      es: {
        title: '@:routes.projectConfiguration',
        config: {
          title: 'Configuración del entorno',
          description: 'La configuración del entorno se compone de pares clave/valor. Definidas en el archivo del stack <code>.forms.yml</code>, las variables se completan al crear un entorno y se pueden editar en cualquier momento.',
        },
        scheduling: {
          sectionTitle: 'Programación',
          title: 'Programar',
          description: 'Configure el inicio y la detención automática para este entorno.',
        },
        delete: {
          title: 'Borrar entorno',
          description: 'Eliminar este entorno es una acción permanente que no se puede deshacer. Los recursos creados a través de pipelines no se eliminarán automáticamente.',
          modalDescriptionHeader: 'Borrar un entorno no borrará los recursos desplegados por la pipeline.',
          modalDescription: 'Por favor, bórrelos, usando la tarea de la pipeline o manualmente, ya que pueden generar costes.',
          modalConfirm: '¿Realmente quiere borrar este entorno?',
        },
      },
      fr: {
        title: '@:routes.projectConfiguration',
        config: {
          title: `Configuration de l'environnement`,
          description: `La configuration de l'environnement est composée de paires clé/valeur. Définies dans le fichier de stack <code>.forms.yml</code>, les variables sont renseignées lors de la création d'un environnement et peuvent être modifiées à tout moment.`,
        },
        scheduling: {
          sectionTitle: 'Planification',
          title: 'Planning',
          description: `Configurez le démarrage et l'arrêt automatiques pour cet environnement.`,
        },
        delete: {
          title: `Supprimer l'environnment`,
          description: 'La suppression de cet environnement est une action permanente et irréversible. Les ressources créées via les pipelines ne seront pas supprimées automatiquement.',
          modalDescriptionHeader: `La suppression d'un environnement ne supprimera pas les ressources déployées par la pipeline.`,
          modalDescription: 'Supprimez-les via la pipeline ou manuellement, car ils peuvent générer des coûts supplémentaires.',
          modalConfirm: 'Voulez-vous toujours supprimer cet environnement ?',
        },
      },
    },
  },
}
</script>

<style lang="scss">
@import "@/assets/styles/scss/components/layouts";

.project-configuration-environment {
  &__icon {
    position: relative;
  }

  .icon-picker {
    display: inline-block;
  }

  label {
    color: get-color("grey", "dark-2");
  }

  .card-wrapper {
    display: flex;
    gap: $spacer * 4;

    &__avatar  {
      opacity: 0.8;
    }

    .cy-avatar__icon {
      font-size: 24px !important;
    }

    .credential-icon {
      position: relative;
      z-index: 0;
      min-width: 32px;
      margin-right: 0;
      border: 1px solid get-color("white");
      border-radius: 4px;
      padding: $spacer;
      background: #623CE433;
    }

    &__text {
      display: flex;
      gap: $spacer * 4;
      flex-direction: row;
      flex-grow: 1;
      justify-content: space-between;
    }
  }

  .content {
    align-content: center;

    &__link {
      grid-row-start: title;
      grid-row-end: subtitle;
      place-items: center;
      display: flex;
    }

    &__button {
      display: inline-flex;
      align-items: center;

      .v-icon {
        color: get-color("primary");
      }
    }
  }

  .danger-zone {
    &__title {
      @extend .two-column-section__header;
    }

    &__content {
      @extend .two-column-section__content;

      background-color: get-color("white");

      .delete-section {
        &__title {
          @extend .content__title;
        }

        &__text {
          @extend .content__subtitle;
        }
      }
    }
  }
}
</style>
